var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ip-info-wrapper" }, [
    _c("p", { staticClass: "ip-address" }, [_vm._v(_vm._s(_vm.ipAddr))]),
    _c("div", { staticClass: "region-wrapper" }, [
      _c("img", {
        staticClass: "flag-image",
        attrs: { src: _vm.flagImg, alt: "Flag" },
      }),
      _c("div", { staticClass: "info-text" }, [
        _c("p", { staticClass: "isp-name" }, [_vm._v(_vm._s(_vm.ispName))]),
        _c(
          "a",
          {
            staticClass: "ip-location",
            attrs: { href: _vm.mapsUrl, title: "🗺️ Open in Maps" },
          },
          [_vm._v(" " + _vm._s(_vm.location) + " ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }